import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import Box from 'common/src/components/Box';
import Fade from 'react-reveal/Fade';
import Image from 'common/src/components/Image';
import { RiskReversalInner, ContentWrapper } from './riskReversalBlock.style';

const RiskReversalBlock = ({ row, colHeading, colContent }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        riskReversalData {
          title
          text
          slogan
          image {
            publicURL
          }
        }
      }
    }
  `);

  const { slogan, text, image } = data.charityJson.riskReversalData;

  return (
    <RiskReversalInner className="riskReversalInner">
      <Container id="riskReversal" width="1660px">
        <Box className="row" {...row}>
          <Box className="col colHeading" {...colHeading}>
            <Fade>
              <div className="imageMoneyBack">
                <Image
                  src={image.publicURL}
                  alt="30 days money back garantee"
                />
              </div>
            </Fade>
          </Box>
          <Box className="col colContent" {...colContent}>
            <ContentWrapper>
              <Heading as="h5" content={slogan} />
              {/* <Heading content={title} /> */}
              <Text content={text} />
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </RiskReversalInner>
  );
};

RiskReversalBlock.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  colHeading: PropTypes.object,
  colContent: PropTypes.object,
};

RiskReversalBlock.defaultProps = {
  sectionWrapper: {
    id: 'faq_section',
    as: 'section',
    pt: ['60px', '80px', '80px', '80px'],
    pb: ['60px', '80px', '80px', '80px'],
    bg: '#f9fbfd',
  },
  secTitleWrapper: {
    mb: ['55px', '75px'],
  },

  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    // alignItems: 'flex-start',
    ml: '-15px',
    mr: '-15px',
  },

  colHeading: {
    width: ['100%', '80%', '33%'],
    display: 'flex',
    alignItems: 'center',
    pl: '15px',
    pr: '15px',
    // mb: '30px',
  },

  colContent: {
    width: ['100%', '100%', '66%'],
    maxWidth: '800px',
    display: 'flex',
    alignItems: 'center',
    pl: '15px',
    pr: '15px',
    // mb: '30px',
  },

  descriptionStyle: {
    width: 'calc(100% - 2rem)',
    // fontSize: `${3}`,
    // color: 'textColor',
    // lineHeight: '1.75',
    // mb: `${0}`,
  },
};

export default RiskReversalBlock;
