import styled from 'styled-components';
import { themeGet } from 'styled-system';

const BlockWrapper = styled.div`
  h5 {
    font-size: 1rem;
    line-height: 1.2;
    text-transform: uppercase;
    color: ${themeGet('colors.secondary2', '#4517ff')};
    margin-bottom: 30px;
    @media only screen and (max-width: 1440px) {
      margin-bottom: 27px;
    }
    @media only screen and (max-width: 1360px) {
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }

  .descriptionStyle {
    color: ${themeGet('colors.primary', '#4517ff')};
    max-width: 600px;
    padding: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto;
  }    
  }
`;

export const ButtonPrice = styled.button`
  border: 0;
  padding: 0;
  width: 100%;
  min-width: 150px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  border-radius: 5px;
  font-size: 1.2rem;
  padding: 1.6rem;

  background-color: ${themeGet('colors.white', '#27E8A2')};
  border: 2px solid ${themeGet('colors.uiPurpleBlue', '#27E8A2')};

  a,
  a span {
    margin-right: 0;
    color: ${themeGet('colors.uiPurpleBlue', '#060F1E')};
  }

  &:hover,
  &:focus {
    background-color: ${themeGet('colors.uiPurpleBlue', '#ECF22F')};
    opacity: 0.9;
    content: 0;
    box-shadow: none;

    a,
    a span {
      color: ${themeGet('colors.white', '#060F1E')};
    }
  }

  &.highlight {
    background-color: ${themeGet('colors.uiBlueLink', '#27E8A2')};
    border: 2px solid ${themeGet('colors.uiBlueLink', '#27E8A2')};

    a,
    a span {
      color: ${themeGet('colors.white', '#060F1E')};
    }

    &:hover,
    &:focus {
      background-color: ${themeGet('colors.uiBlueLinkHover', '#ECF22F')};
      border: 2px solid ${themeGet('colors.uiBlueLinkHover', '#27E8A2')};
      // opacity: 0.8;
      content: 0;
      box-shadow: none;

      a,
      a span {
        color: ${themeGet('colors.white', '#060F1E')};
      }
    }
  }

  @media only screen and (max-width: 1440px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 1360px) {
    // font-size: 14px;
  }

  img {
    margin-left: 11px;
    @media only screen and (max-width: 1440px) {
      margin-left: 10px;
    }
  }
`;

const PricingTableWrapper = styled.div`
  display: flex;  
  width: 100% !important;
  justify-content: center;
  align-items: stretch;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
 
  .glide__slides {
    .glide__slide {
      height: auto;
      padding-bottom: 1rem; // to  be able to see the shadow of PricingTable

      .highlight {
        // border: 2px solid ${themeGet('colors.uiGreen', '#6b6d96')};
      }      
    }
  }  
`;

const PricingTable = styled.div`
  border: 1px solid #f9fafb;
  padding: 45px 45px 50px 45px;
  background-color: ${themeGet('colors.white', '#6b6d96')};
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.09), 0 2px 5px rgba(0, 0, 0, 0.08);
  // box-shadow: 0 5px 50px -5px rgba(0,0,0,0.1); // egghead style
  border-radius: 12px;
  max-width: 380px;
  width: 100%;
  height: auto;
  margin: 0 1rem;

  transition: 0.25s ease-in-out;
  max-width: 400px;

  @media only screen and (max-width: 575px) {
    margin: 0 0 2rem;
  }

  @media (max-width: 767px) {
    padding: 45px 35px;
  }
  &:hover {
    background-color: #fff;
    border-color: #f2f4f7;
  }

  .listContentStyle {
    text-align: center;
  }

  .priceLabelWrapper {
    font-size: 1rem;
    text-align: center;
  }

  .priceLabel {
  }

  .priceLabelDiscount {
    background-color: ${themeGet('colors.uiGreen', '#4517ff')};
    color: ${themeGet('colors.primary', '#4517ff')};
    padding: 0.125em 0.5em;
    border-radius: 0.25em;
  }

  .priceWrapper {
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }

  .price {
    font-size: 3.5rem;
    display: block;
    color: ${themeGet('colors.primary', '#4517ff')};
  }

  .currency {
    font-size: 0.8rem;
    padding: 1rem 0.5rem 0 0;
    display: block;
  }
`;

const PricingHead = styled.div`
  margin-bottom: 35px;
`;

const PricingPrice = styled.div`
  margin-bottom: 50px;
`;

const PricingButton = styled.div`
  text-align: center;
`;

const PricingList = styled.div`
  margin: 3rem 0 1rem;
  text-align: center;
`;

const ListItem = styled.div`
  display: flex;
  margin-bottom: 23px;
  justify-content: center;
  &:last-child {
    margin-bottom: 0;
  }
  .price_list_icon {
    color: #18d379;
    margin-right: 10px;
  }
`;

const SwitchWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  .reusecore__switch {
    .reusecore__field-label {
      font-size: 16px;

      color: #5c636c;
      cursor: pointer;
    }
    input[type='checkbox'] {
      &:checked {
        + div {
          width: 40px !important;
          background-color: ${themeGet('colors.primary')};
          > div {
            left: 17px !important;
          }
        }
      }
      + div {
        background-color: #f0f0f0;
        border: 0;
        width: 40px;
        height: 25px;
        > div {
          background-color: #fff;
          box-shadow: 0px 2px 3px 0.24px rgba(31, 64, 104, 0.25);
          width: 21px;
          height: 21px;
          top: 2px;
          left: 2px;
        }
      }
    }
  }
`;

const PricingButtonWrapper = styled.div`
  text-align: center;
  margin-top: 1rem;
  position: relative;
  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    // background: ${themeGet('colors.logoPink', '#4517ff')};
    display: block;
    bottom: 0;
    left: 0;
  }
  .reusecore__button {
    font-size: 18px;
    
    color: #90949b;
    background: transparent;
    height: auto;
    min-width: 200px;
    border: none;
    padding-bottom: 30px;
    position: relative;
    transition: 0.15s ease-in-out;
    @media (max-width: 767px) {
      min-width: 0;
      padding: 0 15px 10px 15px;
      font-size: 14px;
    }
    &:hover {
      color: ${themeGet('colors.secondary2', '#4517ff')};
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      display: block;
      background: ${themeGet('colors.secondary2', '#4517ff')};
      transform: scaleX(0);
      transform-origin: right center 0;
      transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    }
    &.active-item {
      color: ${themeGet('colors.secondary2', '#4517ff')};
      &:after {
        transform: scaleX(1);
        transform-origin: left center 0;
        transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
      }
    }
    @media (max-width: 575px) {
      font-size: 14px;
      height: 44px;
      width: 120px;
      padding: 0 5px;
    }
  }
  > a {
    font-size: 18px;
    
    color: #3b93ff;
    padding: 0 40px;
    @media (max-width: 767px) {
      padding: 0 15px 10px 15px;
      font-size: 14px;
      height: 46px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export {
  PricingTable,
  PricingTableWrapper,
  PricingHead,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  SwitchWrapper,
  PricingButtonWrapper,
};
export default BlockWrapper;
