import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import { setStripePriceId, setStripePriceAmount } from '../../../utils/stripe';
import BlockWrapper, {
  PricingTable,
  PricingHead,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  PricingButtonWrapper,
  PricingTableWrapper,
  ButtonPrice,
} from './pricing.style';

import prices from './options'; // replace prices and price
import { strike } from 'aws-amplify';
// we'll get stripe price_id from options like this prices[pricingTable.price_id]
// this way we can use process.env.GATSBY_PRICE_1 and process.env.GATSBY_PRICE_2

const PricingSection = ({
  sectionWrapper,
  secTitleWrapper,
  secHeading,
  nameStyle,
  listContentStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      charityJson {
        pricing {
          title
          slogan
          features {
            id
            title
            description
            icon {
              publicURL
            }
          }
        }
        INDIVIDUAL_PRICING {
          title
          description
          items {
            name
            highlight
            description
            currency
            price
            price_id
            priceLabel
            priceLabelDiscount
            # trialURL
            # trialButtonLabel
            buttonLabel
            url
            productStripe
            listItems {
              content
            }
          }
        }
        TEAM_PRICING {
          title
          description
          items {
            name
            description
            currency
            price
            priceLabel
            priceLabelDiscount
            # trialURL
            # trialButtonLabel
            buttonLabel
            url
            productStripe
            listItems {
              content
            }
          }
        }
      }
    }
  `);

  const [state, setState] = useState({
    data: Data.charityJson.INDIVIDUAL_PRICING,
    active: true,
  });

  const data = state.data;
  const activeStatus = state.active;

  const dataCommon = Data.charityJson.pricing;

  const pricingCarouselOptions = {
    type: 'slider',
    perView: 3,
    gap: 30,
    bound: true,
    breakpoints: {
      1199: {
        perView: 2,
        peek: {
          before: 100,
          after: 100,
        },
      },
      990: {
        perView: 1,
        peek: {
          before: 160,
          after: 160,
        },
      },
      767: {
        perView: 1,
        peek: {
          before: 80,
          after: 80,
        },
      },
      575: {
        perView: 1,
        gap: 15,
        peek: {
          before: 20,
          after: 20,
        },
      },
    },
  };

  return (
    <Box {...sectionWrapper} id="pricing_section">
      <BlockWrapper>
        <Container>
          <Box {...secTitleWrapper}>
            {/* <Heading as="h5" {...secText} content={dataCommon.slogan} /> */}
            <Heading {...secHeading} content={data.title} />
            <PricingButtonWrapper>
              <Button
                title="Individual"
                className={activeStatus ? 'active-item' : ''}
                onClick={() =>
                  setState({
                    data: Data.charityJson.INDIVIDUAL_PRICING,
                    active: true,
                  })
                }
              />
              <Button
                title="Team"
                className={activeStatus === false ? 'active-item' : ''}
                onClick={() =>
                  setState({
                    data: Data.charityJson.TEAM_PRICING,
                    active: false,
                  })
                }
              />
              {/* <a href="#1">+ Custom Plan</a> */}
            </PricingButtonWrapper>
          </Box>
          <Heading
            as="h3"
            className="descriptionStyle"
            content={["Get your ", <span className="highlightText">lifetime access for 20% of the price today</span>, " and join us on our mission to build a global, inclusive, interactive school for remote work."]}
          />
          <PricingTableWrapper>
            <>
              {data.items.map((pricingTable, index) => (
                <PricingTable
                  key={`pricing-table-${index}`}
                  freePlan={pricingTable.freePlan}
                  className={
                    pricingTable.highlight
                      ? 'pricing_table highlight'
                      : 'pricing_table'
                  }
                >
                  <PricingHead>
                    <Heading content={pricingTable.name} {...nameStyle} />
                    {/* <Text
                        content={pricingTable.description}
                        {...descriptionStyle}
                      /> */}
                  </PricingHead>
                  <PricingPrice>
                    {pricingTable.price && <p className="priceWrapper">
                      <strike>
                        {/* <span className="currency">{pricingTable.currency}</span> */}
                        <span className="price"> ${pricingTable.price}</span>
                      </strike>
                    </p>
                    }
                    {pricingTable.price && <p className="priceWrapper">
                      <span className="currency">{pricingTable.currency}</span>
                      <span className="price"> $70</span>
                    </p>}
                    <p className="priceLabelWrapper">
                      {pricingTable.priceLabelDiscount && (
                        <span className="priceLabelDiscount">
                          {pricingTable.priceLabelDiscount}
                        </span>
                      )}
                      <span className="priceLabel">
                        {' '}
                        {pricingTable.priceLabel}
                      </span>
                    </p>
                  </PricingPrice>
                  <PricingButton>
                    <ButtonPrice
                      className={pricingTable.highlight ? 'highlight' : ''}
                    >
                      <Link
                        to={
                          pricingTable.url +
                          '?price=' +
                          prices[pricingTable.price_id]
                        }
                        onClick={() => {
                          setStripePriceId(prices[pricingTable.price_id]);
                          setStripePriceAmount(pricingTable.price);
                        }}
                      >
                        <span className="text">{pricingTable.buttonLabel}</span>
                      </Link>
                      {/* <Image src={heartImage} alt="Charity Landing" /> */}
                    </ButtonPrice>
                  </PricingButton>
                  <PricingList className="PricingList">
                    <Heading
                      as="h5"
                      content={pricingTable.name + ' features:'}
                    />
                    {pricingTable.listItems.map((item, index) => (
                      <ListItem key={`pricing-table-list-${index}`}>
                        <Text
                          className="listContentStyle"
                          content={item.content}
                          {...listContentStyle}
                        />
                      </ListItem>
                    ))}
                  </PricingList>
                </PricingTable>
              ))}
            </>
          </PricingTableWrapper>
        </Container>
      </BlockWrapper>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['2rem'],
    pb: '20px',
  },
  secTitleWrapper: {
    mb: ['30px', '40px'],
  },
  secText: {
    as: 'h5',
    display: 'block',
    textAlign: 'center',
    letterSpacing: '0.15em',
    mb: '12px',
  },
  secHeading: {
    textAlign: 'center',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    width: '500px',
    maxWidth: '100%',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    color: '#0f2137',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '18px', '20px', '22px'],
    maxWidth: '500px',
    color: '#6e7379',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: '#0f2137',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: '#6e7379',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',

    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    // bg: '#fff',
    // color: '#2aa275',
    // colors: 'primaryWithBg',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '15px',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    // colors: 'primaryWithBg',
    minWidth: ['160px', '190px'],
    maxWidth: '100%',
    height: '48px',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#6e7379',
    mb: '0',
  },
};

export default PricingSection;
